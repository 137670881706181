import { accountTypes } from "./accountTypes";
import { profileStatuses } from "./profileStatuses";

export const isConsultant = (user) => {
    return user?.attributes?.profile?.publicData?.accountType === accountTypes.consultant;
};

export const isClient = (user) => {
    return user?.attributes?.profile?.publicData?.accountType === accountTypes.client;
};

export const getAccountType = (user) => {
    return user?.attributes?.profile?.publicData?.accountType
};

export const getIsPendingVerification = (user) => {
    return user?.attributes?.profile?.publicData?.profileStatus === profileStatuses.pendingVerification;
};

export const getIsVettedUser = (user) => {
    return user?.attributes?.profile?.publicData?.profileStatus === profileStatuses.vetted;
};


export const getUserStatus = (user) => {
    return user?.attributes?.profile?.publicData?.profileStatus ? user?.attributes?.profile?.publicData?.profileStatus : profileStatuses.none;
};

export const getIsClient = (user) => {
    return user?.attributes?.profile?.publicData?.accountType === accountTypes.client;
};

export const getIsConsultant = (user) => {
    return user?.attributes?.profile?.publicData?.accountType === accountTypes.consultant;
};

export const getUserAvailability = (user) => {
    return user?.attributes?.profile?.publicData?.availability;
};

export const getProfileListingId = (user) => {
    return user?.attributes?.profile?.protectedData?.profileListingId;
};